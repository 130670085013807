<div
  class="product-lock-section"
  *ngIf="shouldShowStockDistribution && currentProduct.availabilityInfo?.stockInfo"
>
  <div class="lock-product-container main-box">
    <div class="lock-product-icon">
      <img src="assets/img/locked-products-lock.svg" />
    </div>
    <span class="caption1--regular">{{
      'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.LOCKED_ON_YOU' | translate
    }}</span>
  </div>
  <ng-container *ngIf="currentProduct.availabilityInfo?.stockInfo.expiresAt && !stockIsExpired">
    <div class="locked-period">
      <p class="caption1--regular">
        {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TO_BE_UNLOCKED' | translate }}:
      </p>
      <div
        class="remaining-period-status"
        [ngClass]="remainingDays! > 7 && !remainingHours ? 'status-success' : 'status-error'"
      >
        <img src="assets/img/timer.svg" />
        <span
          >{{ !remainingHours ? remainingDays : remainingHours }}
          <span *ngIf="!remainingHours">{{
            'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.LOCKED_DAYS' | translate
          }}</span>
          <span *ngIf="remainingHours">{{
            'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.LOCKED_HOURS' | translate
          }}</span>
        </span>
        <span *ngIf="remainingDays! < 3 || remainingHours">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.DONT_MISS_OUT' | translate }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="stockIsExpired">
    <div class="expiry-product-container secondary-box">
      <div class="lock-product-icon">
        <img src="{{ assetsPath + 'white-box.svg' }}" />
      </div>
      <span class="caption1--regular mis-4" *ngIf="stockExpiryType === 'quantity'">{{
        'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.QUANTITY_EXPIRED' | translate
      }}</span>
      <span class="caption1--regular mis-4" *ngIf="stockExpiryType === 'time'">{{
        'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TIME_EXPIRED' | translate
      }}</span>
      <span class="caption1--regular">
        {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.EXPIRY_LABEL' | translate }}
      </span>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="stockIsExpired">
  <div class="contact-manager-section">
    <p class="caption1--bold">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.EXPIRY_NOTICE_HEAD' | translate }}
    </p>
    <p class="caption2--regular">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.EXPIRY_NOTICE_TEXT' | translate }}.
    </p>
  </div>
</ng-container>

<ng-container *ngIf="!saleInfo; else productSaleDetails">
  <section class="basic-info">
    <div class="discounted-product-alert mt-0" *ngIf="isDiscountAsSecondProduct">
      <img src="assets/img/flash-sign.svg" />
      <p class="discounted-product-alert__text">
        {{ 'CATEGORY_PAGE.SECOND_PRODUCT_DISCOUNT' | translate }}
      </p>
    </div>
    <div class="basic-info-container">
      <div class="base-container">
        <div class="product-basic-info-container">
          <p class="product-name">
            {{ basicInfo.productName }}
          </p>
          <p class="product-id">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_ID' | translate }}:
            <span>{{ basicInfo.productId }}</span>
          </p>
          <div
            class="marketplace-container"
            *ngIf="basicInfo.type === 'bundle' && isMarketplaceEnabled"
          >
            <div class="prod-list-container">
              <div
                class="bundle-product"
                *ngFor="let variant of basicInfo.bundleVariants"
                (click)="navigateToProduct(variant)"
              >
                <img [src]="variant.picture" />
                <p class="caption1--bold">{{ variant.id }}</p>
              </div>
            </div>
          </div>
          <p class="body2--bold dark-content-yellow">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.START_SELLING' | translate }}
          </p>
          <app-product-variants
            [variantsData]="variantsData"
            (variantSelected)="onSelectVariant($event)"
          ></app-product-variants>
          <div class="header-container" *ngIf="!isDiscountOnQuantityEnabled">
            <div>
              <p class="content-secondary-color body2--medium">
                {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PRICE_HEADER' | translate }}
              </p>
              <section class="profit-info-section">
                <p class="profit-text body2--medium">
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PRICE' | translate }}:
                </p>
                <p class="product-price body2--medium">{{ basicInfo.productPrice }}</p>
                <p class="currency body2--medium">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                </p>
                <p class="profit-text margin-gap body2--medium">
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PROFIT' | translate }}:
                </p>
                <p class="product-profit body2--medium">{{ basicInfo.productProfit }}</p>
                <p class="currency body2--medium">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                </p>
              </section>
            </div>
          </div>
        </div>
        <div
          class="add-to-store"
          *ngIf="userHasStore"
          [ngClass]="productAddedToStore ? 'add-to-store__added' : ''"
        >
          <button
            class="add-to-store__btn"
            (click)="onAddProductToStore()"
            [disabled]="
              productAvailability === productAvailabilityEnum.not_available || !productIsOrderable
            "
          >
            <img
              class="add-to-store__btn__store-icon"
              alt="add-to-store-icon"
              src="{{
                productAddedToStore
                  ? assetsPath + 'added-store.svg'
                  : assetsPath + 'header-icons/shop-basket.svg'
              }}"
            />
            <p *ngIf="!productAddedToStore">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_STORE_ADD' | translate }}
            </p>
            <p *ngIf="productAddedToStore">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_ADDED_TO_STORE' | translate }}
            </p>
            <img
              *ngIf="!productAddedToStore"
              class="add-to-store__btn__add-icon"
              src="{{ assetsPath + 'add-icon.svg' }}"
            />
          </button>
          <span class="store-tooltip">
            <img src="{{ assetsPath + storeProvider + '.svg' }}" />
            <p>{{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.ADD_TO_YOUR_STORE' | translate }}</p>
          </span>
        </div>
      </div>
      <ng-template [ngTemplateOutlet]="selfServe"></ng-template>
    </div>
  </section>
</ng-container>
<ng-template #productSaleDetails>
  <section class="basic-info">
    <div class="basic-info-container">
      <div class="base-container">
        <div class="product-basic-info-container">
          <p class="product-name">
            {{ basicInfo.productName }}
          </p>
          <p class="product-id">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_ID' | translate }}:
            <span>{{ basicInfo.productId }}</span>
          </p>
          <p class="body2--bold dark-content-yellow">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.START_SELLING' | translate }}
          </p>
          <app-product-variants
            [variantsData]="variantsData"
            (variantSelected)="onSelectVariant($event)"
          ></app-product-variants>
          <div class="header-container" *ngIf="!isDiscountOnQuantityEnabled">
            <div>
              <p class="content-secondary-color body2--medium">
                {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PRICE_HEADER' | translate }}
              </p>
              <section
                class="profit-info-section"
                *ngIf="!saleInfo.productProfit || !saleInfo.productPrice"
              >
                <p class="profit-text body2--medium">
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PRICE' | translate }}:
                </p>
                <p class="product-price body2--medium">{{ basicInfo.productPrice }}</p>
                <p class="currency body2--medium">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                </p>
                <p class="profit-text body2--medium">
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PROFIT' | translate }}:
                </p>
                <p class="product-profit body2--medium">{{ basicInfo.productProfit }}</p>
                <p class="currency body2--medium">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                </p>
              </section>
              <section class="sale-info-section" *ngIf="!isDiscountOnQuantityEnabled">
                <div class="sale-price">
                  <p class="body2--medium">
                    {{ 'PRODUCTS_PAGE.PRODUCT_CARD.MIN_SELLING_PRICE' | translate }}
                  </p>
                  <div class="sale-value">
                    <ng-container *ngIf="saleInfo.productPrice; else showOriginalPrice">
                      <p class="old-sale-value body2--medium">
                        <s class="body2--medium">{{ basicInfo.productPrice }}</s>
                      </p>
                      <p class="product-price body2--medium">{{ saleInfo?.productPrice }}</p>
                      <p class="currency body2--medium">
                        {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                      </p>
                    </ng-container>
                    <ng-template #showOriginalPrice>
                      <p class="product-price body2--medium">{{ basicInfo.productPrice }}</p>
                      <p class="currency body2--medium">
                        {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                      </p>
                    </ng-template>
                  </div>
                </div>
                <div class="sale-profit">
                  <p class="body2--medium">
                    {{ 'PRODUCTS_PAGE.PRODUCT_CARD.MIN_PROFIT_FOR_YOU' | translate }}
                  </p>
                  <div class="sale-value body2--medium">
                    <ng-container *ngIf="saleInfo.productProfit; else showOriginalProfit">
                      <p class="old-sale-value body2--medium">
                        <s class="body2--medium">{{ basicInfo.productProfit }}</s>
                      </p>
                      <p class="product-profit body2--medium">{{ saleInfo?.productProfit }}</p>
                      <p class="currency body2--medium">
                        {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                      </p>
                    </ng-container>
                    <ng-template #showOriginalProfit>
                      <p class="product-profit body2--medium">{{ basicInfo.productProfit }}</p>
                      <p class="currency body2--medium">
                        {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}
                      </p>
                    </ng-template>
                  </div>
                </div>
                <div class="sale-details" [ngClass]="{ 'sale-details__no-title': !flashSaleTitle }">
                  <button *ngIf="flashSaleTitle" class="sale-title body2--medium">
                    عروض {{ flashSaleTitle }}
                  </button>
                  <button
                    class="sale-timer body2--medium"
                    [ngClass]="{ 'sale-timer__no-title': !flashSaleTitle }"
                  >
                    {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.LIMITED_TIME_OFFER' | translate }}
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div
          class="add-to-store"
          *ngIf="userHasStore"
          [ngClass]="productAddedToStore ? 'add-to-store__added' : ''"
        >
          <button
            class="add-to-store__btn"
            (click)="onAddProductToStore()"
            [disabled]="
              productAvailability === productAvailabilityEnum.not_available || !productIsOrderable
            "
          >
            <img
              class="add-to-store__btn__store-icon"
              alt="add-to-store-icon"
              src="{{
                productAddedToStore
                  ? assetsPath + 'added-store.svg'
                  : assetsPath + 'header-icons/shop-basket.svg'
              }}"
            />
            <p *ngIf="!productAddedToStore">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_STORE_ADD' | translate }}
            </p>
            <p *ngIf="productAddedToStore">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_ADDED_TO_STORE' | translate }}
            </p>
            <img
              *ngIf="!productAddedToStore"
              class="add-to-store__btn__add-icon"
              src="{{ assetsPath + 'add-icon.svg' }}"
            />
          </button>
          <span class="store-tooltip">
            <img src="{{ assetsPath + storeProvider + '.svg' }}" />
            <p>{{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.ADD_TO_YOUR_STORE' | translate }}</p>
          </span>
        </div>
      </div>
      <ng-template [ngTemplateOutlet]="selfServe"></ng-template>
    </div>
  </section>
</ng-template>
<hr *ngIf="isDiscountOnQuantityEnabled" />
<app-product-quantity-offers
  *ngIf="isDiscountOnQuantityEnabled"
  [basicInfo]="basicInfo"
  [currentProduct]="currentProduct"
  [productAvailability]="productAvailability"
  [productIsOrderable]="productIsOrderable"
  [saleInfo]="saleInfo"
  (addToCart)="onAddToCart()"
  (orderNow)="onOrderNow($event)"
></app-product-quantity-offers>
<div
  class="header-container"
  *ngIf="
    !isUserEligibleForCPA &&
    isPriceRangeEnabled &&
    basicInfo.productPriceRange?.min &&
    basicInfo.productPriceRange?.max
  "
>
  <div>
    <p class="range-title">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TAAGER_RECOMMENDATION' | translate }}
    </p>
    <section class="price-range-section">
      <div class="range-container">
        <div class="range-container__price-container">
          <p class="range-container__price-container__title">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.SELLING_PRICE_RECOMMENDATION' | translate }}
          </p>
          <div class="price-container">
            <span class="title"
              >{{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.MINIMUM' | translate }}:
            </span>
            <span class="amount"
              >{{ basicInfo.productPriceRange?.min }}
              <span class="currency">
                {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
              ></span
            >
            <span class="range">{{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TILL' | translate
            }}</span>
            <span class="title"
              >{{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.MAXIMUM' | translate }}:</span
            >
            <span class="amount"
              >{{ basicInfo.productPriceRange.max }}
              <span class="currency">
                {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
              ></span
            >
          </div>
        </div>
        <div class="range-container__profit-container">
          <p class="range-container__profit-container__title">
            {{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.SELLING_PROFIT_RECOMMENDATION' | translate
            }}:
          </p>
          <div class="price-container">
            <span class="amount profit-min"
              >{{ basicInfo.productPriceRange?.expectedProfitMin }}
              <span class="currency">
                {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
              ></span
            >
            <span class="range">{{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TILL' | translate
            }}</span>
            <span class="amount profit-max"
              >{{ basicInfo.productPriceRange.expectedProfitMax }}
              <span class="currency">
                {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
              ></span
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<app-cpa-calculator
  *ngIf="isUserEligibleForCPA"
  [basicInfo]="basicInfo"
  [productObjectId]="currentProduct._id"
  [productAnalytics]="skuAnalytics"
></app-cpa-calculator>
<ng-template #selfServe>
  <div
    class="header-container"
    *ngIf="
      (userHasStockAvailability &&
        productAvailability !== productAvailabilityEnum.available_for_preorder) ||
      skuAnalyticsEnabled
    "
    [ngClass]="{
      'header-container__top-separator':
        variantsData.colorVariantArr.length || variantsData.sizeVariantArr.length
    }"
  >
    <div>
      <div class="head-text">
        <p class="content-secondary-color body2--medium">
          {{
            'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_AVAILABILITY_PERFORMANCE' | translate
          }}
        </p>
        <div
          class="tooltip-wrapper"
          *ngIf="
            shouldShowStockDistribution &&
            currentProduct.availabilityInfo?.stockInfo &&
            !currentProduct.availabilityInfo?.stockInfo.totalLockedStock
          "
        >
          <span class="icon">?</span>
          <div class="tooltip">
            <span class="caption2--regular tooltip-heading">{{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.FREE_LOCKING' | translate
            }}</span>
            <div class="start-border caption2--regular">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.FREE_LOCKING_TEXT' | translate }}
              <span class="fl-link" (click)="navigateToPreOrderPage()">
                {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.FREE_LOCKING_URL' | translate }}.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="self-serve-container">
        <ng-container
          *ngIf="
            shouldShowStockDistribution &&
              currentProduct.availabilityInfo?.stockInfo?.totalLockedStock &&
              !stockIsExpired;
            else noStockInfo
          "
        >
          <div class="stock-availability-container locked-quantity-container">
            <div class="stock-availability-status stock-availability-status__available">
              <div class="stock-availability-container__header">
                <p class="body2--bold color-primary">
                  {{
                    currentProduct.availabilityInfo?.stockInfo?.totalLockedStock -
                      currentProduct.availabilityInfo?.stockInfo?.remainingStock
                  }}
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.SOLD_OUT_OF' | translate }}
                  {{ currentProduct.availabilityInfo?.stockInfo?.totalLockedStock }}
                </p>
              </div>
              <div class="progress-bar">
                <mat-progress-bar
                  mode="determinate"
                  [value]="
                    ((currentProduct.availabilityInfo?.stockInfo?.totalLockedStock -
                      currentProduct.availabilityInfo?.stockInfo?.remainingStock) *
                      100) /
                    currentProduct.availabilityInfo?.stockInfo?.totalLockedStock
                  "
                ></mat-progress-bar>
              </div>
              <div class="stock-availability-container__header locked_remaining mt-3">
                <img src="{{ assetsPath + 'green-box.svg' }}" />
                <p class="caption3--medium">
                  {{ 'CART.REMAINING' | translate }}
                  <span class="caption1--bold color-primary">
                    <b>{{ currentProduct.availabilityInfo?.stockInfo?.remainingStock }}</b></span
                  >
                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noStockInfo>
          <div class="stock-availability-container" *ngIf="showStockAvailability">
            <div
              class="stock-availability-status"
              [ngClass]="
                stockAvailabilityStatus === 'not_available'
                  ? 'stock-availability-status__not-available'
                  : 'stock-availability-status__available'
              "
            >
              <div class="stock-availability-container__header">
                <img src="{{ assetsPath + 'green-box.svg' }}" />
                <p class="caption2--regular">
                  {{
                    'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.STOCK_AVAILABILITY.NUMBER_OF_PIECES'
                      | translate
                  }}
                </p>
              </div>
              <p
                class="body2--medium"
                [ngClass]="
                  stockAvailabilityStatus === 'not_available'
                    ? 'stock-availability-status__not-available'
                    : 'stock-availability-status__available'
                "
              >
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.STOCK_AVAILABILITY.' +
                    (stockAvailabilityStatus | uppercase) | translate
                }}
              </p>
            </div>
            <p
              class="stock-availability-status stock-availability-status__run-fast body2--medium"
              *ngIf="stockAvailabilityRunRate"
            >
              {{
                'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.STOCK_AVAILABILITY.' +
                  (stockAvailabilityRunRate | uppercase) | translate
              }}
            </p>
          </div>
        </ng-template>
        <div
          class="sku-analytics-container"
          *ngIf="skuAnalyticsEnabled"
          [ngClass]="{
            'sku-analytics-container--no_border':
              productAvailability === productAvailabilityEnum.available_for_preorder
          }"
        >
          <div
            *ngIf="
              skuAnalytics.confirmationRate && skuAnalytics.deliveryRate;
              else analyticsNotAvailable
            "
            class="sku-analytics"
          >
            <div class="sku-analytics-data">
              <p class="sku-analytics-data-text">
                <span class="sku-analytics-data-title body2--medium">{{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.CONFIRMATION_RATE' | translate
                }}</span>
                <span class="sku-analytics-data-value body1--bold"
                  >{{ skuAnalytics.confirmationRate * 100 | number : '1.2-2' }}%</span
                >
              </p>
              <p class="sku-analytics-data-text">
                <span class="sku-analytics-data-title body2--medium">{{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.DELIVERY_RATE' | translate
                }}</span
                ><span class="sku-analytics-data-value body1--bold"
                  >{{ skuAnalytics.deliveryRate * 100 | number : '1.2-2' }}%</span
                >
              </p>
            </div>
            <p class="sku-analytics-title caption1--regular">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.NUMBERS_GENERATED' | translate }}.
            </p>
          </div>
          <ng-template #analyticsNotAvailable>
            <div class="no-sku-analytics body2--medium">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.NO_DATA_DISPLAY' | translate }}.
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
